@import "../../utils/colors.css";

.footerDiv {
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color5);
  background-color: var(--color2);
  /* width: 100vw; */
}

.footerLink {
  text-decoration: none;
  color: var(--color5);
}
