@import "../../utils/colors.css";

.contactFormDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color5);
  background-color: var(--color2);
  border-radius: 5px;
  width: 70%;
  margin: 2rem;
  z-index: 1;
}

.contactFormTitle {
  color: var(--color5);
  font-size: 1.8rem;
}

.contactEmail {
  font-size: 1.3rem;
  color: var(--color4);
  text-decoration: none;
}

.contactPhone {
  color: var(--color5);
  font-size: 1.3rem;
}

.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.formGroup {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0.5rem;
  justify-content: center;
  /* align-items: flex-start; */
}

.formGroup label {
  margin-bottom: 0.2rem;
}

/* .formGroup input {
  color: var(--color1);
  padding: 0.2rem;
  border: 1px solid var(--color4);
} */

.formGroup input:focus,
.formGroup textarea:focus {
  outline: none;
  border: 3px solid var(--color4);
  border-radius: 5px;
}

.custom-btn {
  align-self: center;
  background-color: var(--color4);
  color: var(--color1);
  font-size: 1.3rem;
  margin: 1rem;
  padding: 10px;
  width: fit-content;
  border: 2px solid var(--color4);
  border-radius: 5px;
  cursor: pointer;
}

.custom-btn:hover {
  color: var(--color5);
}

.messageSent {
  text-align: center;
  color: var(--color5);
  font-size: 1.5rem;
}
.messageProblem {
  text-align: center;
  color: var(--warning);
  font-size: 1.5rem;
}

.nameInput,
.emailInput,
.phoneInput,
.messageInput {
  background-color: white;
  font-size: 1.5rem;
  color: var(--color5);
  padding: 0.2rem;
  border: 1px solid var(--color4);
}
