@import "../../utils/colors.css";

header {
  background-color: var(--color4);
}

.headerTitle {
  color: var(--color1);
}

.title {
  font-family: "Shadows Into Light", cursive;
  font-size: 2.5rem;
  color: var(--color1);
}

.navBtn {
  width: 8rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: none;
  background-color: transparent;
  color: var(--color1);
  cursor: pointer;
}

.navBtn:hover {
  color: var(--color5);
}

@media screen and (max-width: 700px) {
  header {
    flex-direction: column;
  }
  .headerTitle {
    align-items: center;
    margin-bottom: 1rem;
  }
  .navBtn {
    border: solid 1px var(--color1);
    border-radius: 50px;
    margin: 1rem;
    font-size: 1.3rem;
    width: auto;
  }
  .navBtns {
    width: 100%;
  }
}
@media screen and (max-width: 390px) {
  .navBtn {
    border: solid 1px var(--color1);
    border-radius: 50px;
    margin: 1rem;
    font-size: 2rem;
    width: auto;
  }
  .navBtns {
    width: 100%;
    flex-direction: column;
  }
}
