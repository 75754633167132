@import "./utils/colors.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* background-color: var(--color2); */
  background-color: var(--color2);
}

img {
  pointer-events: none;
}
