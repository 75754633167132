@import "../../utils/colors.css";

.aboutContainer {
  width: 90vw;
  border-radius: 5px;
  background-color: var(--color2);
  display: flex;
  align-items: center;
  pointer-events: none;
}

.aboutImg {
  width: 60vw;
  height: auto;
  border-radius: 5px 0 0 5px;
}

.aboutText {
  margin: 2rem;
  font-size: 2rem;
  color: var(--color5);
}

@media screen and (max-width: 1000px) {
  .aboutContainer {
    flex-direction: column;
  }
  .aboutImg {
    width: 85vw;
    height: auto;
    border-radius: 5px 0 0 5px;
  }
}
@media screen and (max-width: 600px) {
  .aboutText {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 400px) {
  .aboutText {
    font-size: 1.5rem;
  }
}
